import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { getActions as getSWActions } from 'store/sw';

import './global.css';

if (!document.ie) { // check for ie
  Promise.all([
    import('App'),
  ]).then(([
    { default: App },
  ]) => {
    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(<App />);
  });

  serviceWorker.register({
    onSuccess: () => getSWActions().handleSuccess(),
    onUpdate: reg => getSWActions().handleUpdate(reg),
  });

  reportWebVitals();
}
